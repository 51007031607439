/* all light yellow text elements */
.hero-foot, .burger, .title, .navbar-item, .block, a, strong, label {
    color: #FAF0CA !important;
}
.hero {
    min-height: 100vh !important;
    min-height: calc(100vh - var(--vh-offset, 0px)) !important;
}
.hero-foot {
    padding: 10px;
}
.block {
    font-size: larger;
}
/* BUTTONS FOR CONFIRM AND GO BACK */
#go-back:hover {
    background-color: #FF6666 !important;
}
#confirm:hover {
    background-color: #F4D35E !important;
}
/* all elements with background colour of blue */
.blue, .blue > p > strong {
    background-color: #7B608C !important;
}
.message-body {
    background-color: #ffb6b0 !important;
}

.modal-text, .radio {
    color: #d05655 !important;
}

.modal-text > strong  {
    color: #d05655 !important;
    font-weight: bolder !important;
}
.message-header {
    background-color: #d05655 !important;
    color: #FAF0CA !important;
}
.blue, .blue > p > strong, a.disabled.link > span {
    color: #FAF0CA !important;
}
#header-logo {
    background: url('../images/logo.png') no-repeat center center;
    background-size: cover;
    width: 50px;
}
strong {
    font-weight: bold !important;
}
.clickable:hover {
    cursor: pointer;
    padding: 10px;
}

input[type="radio"] {
    margin-right: 5px;
}

/* .radio {
    color: #FF6666 !important;
} */

.link {
    text-decoration: none !important;
    color: black !important;
}
a.disabled {
    pointer-events: none;
}
.navbar-item:hover {
    background-color: hsla(220, 100%, 1%, 0.2) !important;
}
.form-select {
    background-color: hsla(0, 0%, 100%, 0.5) !important;
}
label {
    text-align: left;
}
.navbar-item {
    font-size: large;
}
.navbar-item.is-active, a.disabled.link > span > i.fa-check {
    color: #F4D35E !important;
}
.is-light, .is-static, .hover-button {
    background-color: #FAF0CA !important;
}
button.is-light:hover, button.is-dark:hover {
    background-color: #f8e9b3 !important;
}
li, a.disabled.link > span, .navbar-menu.is-active {
    background-color: transparent !important;
}
.navbar-menu.is-active {
    background-color: #0D3B66 !important;
}
.navbar-item:hover.not-burger {
    font-size: x-large;
}
/* animations */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}
@keyframes blink {
    from { border-color: transparent }
    to { border-color: #f8e9b3; }
}
.typewriter {
    overflow: hidden;
    border-right: .10em solid #f8e9b3;
    white-space: nowrap;
    width: 0;
    color: #FAF0CA !important;
    font-weight: bold;
}
#long {
    animation: 
        typing 2s steps(50, end) forwards,
        blink .8s infinite;
}
#short {
    animation: 
        typing 0.6s steps(15, end) forwards,
        blink .8s infinite; 
}
.typewriter-container {
    display: inline-block;
}
.lineUp {
    animation: 3s anim-lineUp ease-out;
    color: #FF6666 !important;
  }
  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
.bg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-size: 300% 300%;
    background-attachment: fixed;
    background-image: linear-gradient(
          -45deg, 
          #0D3B66 0%,
          #700353 25%,
          #0D3B66 51%,
          #700353 100%
    );  
    animation: AnimateBG 20s ease infinite;
  }
  
  @keyframes AnimateBG { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  /* .bg-christmas {
    background: #045804;
    animation: color 7s infinite linear;
  }
  @keyframes color {
    0%   { background: #6c0404; }
    40%   { background: #6c0404; }
    50%  { background: #045804; }
    90% {background: #045804;}
    100% { background: #6c0404; }
  } */
/* VALENTINES DAY FORM STYLING .email-field */
.email-field > .field > label, .email-field > .field > p > span > i, .email-field > .field > p > span > input, .is-ghost {
    color: #d05655 !important;
}
.email-field > .field > p > input, .email-field > .field > input {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    border-bottom: #FAF0CA solid 3px !important;
}
.email-submit, .valentine > strong {
    color: #d05655 !important;
}
.is-ghost {
    border: none !important;
    box-shadow: none !important;
}
